import React, { useState, useContext, useEffect } from 'react';
import { navigate, Link } from 'gatsby';
import AuthContext from '../context/AuthProvider';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import Container from '../components/atoms/Container/Container';
import { validateEmail } from '../helpers/general';
import Button from '../components/atoms/Button/Button';
import Dialog from '../components/atoms/Dialog/Dialog';
import { getStorage } from '../helpers/general';

import * as styles from './sign-in.module.css';

const validForm = email => {
  if (!email) {
    return 'Email is required.';
  }
  if (!validateEmail(email)) {
    return 'Invalid email address.';
  }
};

const ForgotPasswordPage = () => {
  const auth = useContext(AuthContext);
  const handleForgotPassword = auth && auth.forgotPassword;
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const userChecked = auth && auth.state.userChecked;
  const userEmail = getStorage('_customEmail');
  const [email, setEmail] = useState('');
  const [fields, setFields] = useState({});
  const [init, setInit] = useState({});

  const [msg, setMessage] = useState({
    error: '',
    success: '',
    dialogSuccess: '',
    dialogError: ''
  });

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/account/`);
    }

    if (!init && userEmail) {
      setFields({ ...fields, ...{ email: userEmail } });
    }
    setInit(true);
  }, [isLoggedIn, userEmail, fields, init]);

  const clearDialog = () => {
    setDialogOpen(false);
    navigate(`/sign-in/`);
  };

  const attemptForgotPassword = (e) => {
    e.preventDefault();
    const validationMsg = validForm(email);
    if (validationMsg) {
      setMessage({ error: validationMsg });
      return;
    }
    handleForgotPassword(email).then(result => {
      // This returns true regardless as we don't confidently get anything back from BC yet.
      if (result) {
        setMessage({
          dialogSuccess:
            'If your email address exists in the system, you will receive an email with instructions to reset your password.'
        });
        setDialogOpen(true);
      } else {
        setMessage({
          dialogError: 'Sorry, something went wrong. Try again later.'
        });
        setDialogOpen(true);
      }
    });
  };

  if (!isLoggedIn && userChecked) {
    return (
      <Layout>
        <Seo title="Reset Password" />
        <Container>
          <div className={`grid grid-50 ${styles.loginContainer} ${styles.forgotPassForm}`}>
            <div className={`${styles.loginForm} center`}>
                <h6>Forgot Your Password?</h6>
                <p className={styles.dialogText}>If you've forgotten your password, enter your email address below and follow the reset instructions</p>
                <form noValidate onSubmit={(e) => attemptForgotPassword(e)}>
                    <div className={`formField ${styles.forgotPasswordInput}`}>
                        <label className={styles.formFieldLabel} htmlFor="email">Email Address</label>
                        <input 
                          type="email" 
                          name="email" 
                          value={email} 
                          placeholder="Email Address"
                          onChange={(e) => setEmail(e.target.value)} 
                        />
                        {msg.error && <p className='error'>{msg.error}</p>}
                    </div>
                    <Button level="primary" type="buttonSubmit">Send</Button>
                </form>
                <div className={styles.forgotPasswordLinkDiv}>
                  <Link to="/sign-in/">Back to Sign in</Link>
                </div>
            </div>
            <div className={styles.createAccount}>
                <h6>New Customer?</h6>
                <p>Create an account with us and you'll be able to:</p>
                <ul className="bullets">
                    <li>Check out faster</li>
                    <li>Save multiple shipping addresses</li>
                    <li>Access your order history</li>
                    <li>Track new orders</li>
                    <li>Save items to your wishlist</li>
                </ul>
                <Button href="/create-account/" level="primary">Create account</Button>
            </div>
          </div>

          <Dialog
            open={dialogOpen}
            title="Reset request made"
            size="sm"
            hideBtnCancel
            disableBackdropClick
            onOk={() => clearDialog()}
          >
            {msg.dialogSuccess && (
              <p className={styles.dialogText}>{msg.dialogSuccess}</p>
            )}
            {msg.dialogError && (
              <p className={styles.dialogText}>{msg.dialogError}</p>
            )}
          </Dialog>
        </Container>
      </Layout>
    );
  } else return null
};

export default ForgotPasswordPage;
